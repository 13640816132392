/* Base styles for .container and .innerBox */
.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to right, #e6e9ec, #5D56DD);
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerBox {
  width: 100%; /* Fill 90% of the available width on all screens */
  max-width: 480px; /* Limit the width for larger screens to 480px */
  background-color: #E9F4FF;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  
}

.heading{
  color: #5D56DD;

}

.footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .error {
  font-weight: bold;
  font-size: 0.875rem;
  color: #ff3300;
  text-align: center;
}

.footer button {
  
 
  padding: 14px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #5d56dd;
  border-radius: 4px;
  background-color: #5d56dd;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fbf8f8;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  
  letter-spacing: 0.32px;
  text-transform: capitalize;

  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  gap: 10px;





}

.footer button:disabled {
  background-color: gray !important;
}

.footer button:hover {
  background-color: transparent;
 
  color: #000000; 
  outline-width: 3px; /* Set outline width to 3px on hover */
}

.footer p {
  font-weight: 700;
  color: #000;
}

.footer p span a {
  font-weight: bold;
  color: #0084ff;
  letter-spacing: 1px;
  font-size: 1rem;
  text-decoration: none;
}


.column {
  display: flex;
  flex-direction: column;
  gap: 0.1px; /* Adjust the gap size as needed */
}












/* Responsive adjustments for mobile phones */
@media screen and (max-width: 480px) {
  .innerBox {
    padding: 20px;
    margin: 20px; /* Add your desired margin value here */
  }

  .footer .error {
    font-size: 0.75rem;
  }

  .footer button {
    font-size: 0.875rem;
    padding: 8px 12px;
     
    border-style: solid;
    border-width: 1px;
    border-color: #5d56dd;
    border-radius: 4px;
    background-color: #5d56dd;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #fbf8f8;
    font-weight: bold;
    
    line-height: 150%;
    
    letter-spacing: 0.32px;
    text-transform: capitalize;
  
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
    gap: 10px;














  }

  .footer button:hover {
    background-color: transparent;
    border-color: #5D56DD;
    color: #000000; 
    outline-width: 3px; /* Set outline width to 3px on hover */
  }

  .footer button:disabled {
    background-color: gray !important;
  }

}